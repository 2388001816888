import React from 'react';
import {WPImage} from "../components/component.wpimage";
import Pagination from "../components/component.pagination";

const TemplateColumns = (props) => {

	let chapter = props.menu_order - 1;
		chapter = (chapter < 10 ? "0" + chapter : chapter);

	return <div className="site-content">

		<div className="page-columns">
			<div className="row no-gutters">
				<div className="col-xl-6">
					{props.fields.page_image &&
						<div className="page-columns-image" style={{backgroundImage : "url(" + props.fields.page_image.sizes.large + ")"}}></div>
					}
				</div>
				<div className="col-xl-6">
					<div className="page-columns-content">
							<div className="page-header text-xl-left">
								<div className="container">
									<span className="number">{chapter}</span>
									<h1>{props.fields.alt_title ? props.fields.alt_title : props.title}</h1>
								</div>
							</div>

							{props.loaded ?
								<div className="page-content fade-in">
									<div className="container">
										{props.fields.page_preamble &&
											<div className="intro text-xl-left">
												<p className="lead">{props.fields.page_preamble}</p>
												<hr></hr>
											</div>
										}

										{props.content &&
											<div dangerouslySetInnerHTML={{__html: props.content}}></div>
										}
									</div>
								</div>
							:
								<div className="loader-lines">
									<div className="container">
										<span></span>
										<span></span>
										<span></span>
										<span></span>
									</div>
								</div>
							}

					</div>
				</div>
			</div>
		</div>


		{props.loaded &&
			<Pagination
				prevChapter={(props.custom.prevChapter.number == 0 ? null : props.custom.prevChapter)}
				nextChapter={(props.custom.nextChapter.title == props.title ? null : props.custom.nextChapter)}>
			</Pagination>
		}



	</div>
}

export default TemplateColumns;
