import React from 'react';

import Header from './component.header';
import Page from './component.page';
import Footer from './component.footer';


const Main = (props) => {
    return <main>
        <Header />
        <div id="wrapper">
            <div id="wrapperInner">
                <Page />
                <Footer />
            </div>
        </div>
    </main>;
}

export default Main;
