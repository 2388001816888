import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    if (!props.general) {
      return null;
    }

    return <footer className="site-footer">
    <div className="container">
        <div className="site-footer-content">
            <a href={props.general.pdf} target="_blank" className="btn btn-outline-light"><i className="icon fal fa-arrow-to-bottom"></i><strong>Hämta formella årsredovisningen</strong></a>
            <p><a href="https://www.sanktkors.se" target="_blank">www.sanktkors.se</a><a href="http://2018.sanktkors.se" target="_blank">Årsredovisning 2018</a></p>
            <Link to="/" className="logo" alt={props.general.title}>
                <img src="../assets/images/logo_inverse.svg"></img>
            </Link>
        </div>

    </div>
    </footer>
}

export default connect(
  state => {
    return {
      general: state.generalState.info
    }
  },
)(Footer);
