import React from 'react';
import SectionImageText from "./section.image_text";
import SectionStats from "./section.stats";
import SectionQuote from "./section.quote";
import SectionNki from "./section.nki";
import SectionDefault from "./section.default";
import SectionImage from "./section.image";
import SectionTable from "./section.table";
import SectionVideo from "./section.video";

const Section = (props) => {
	switch (props.acf_fc_layout) {
		case 'image_text':
			return <SectionImageText {...props} />
		case 'stats':
			return <SectionStats {...props} />
		case 'quote':
			return <SectionQuote {...props} />
		case 'nki':
			return <SectionNki {...props} />
		case 'default':
			return <SectionDefault {...props} />
		case 'image':
			return <SectionImage {...props} />
		case 'table':
			return <SectionTable {...props} />
		case 'video':
			return <SectionVideo {...props} />
	}
	return null;
}

export default Section;
