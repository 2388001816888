import React from 'react';
import { Link } from 'react-router-dom';

const Template404 = (props) => {
    return <div className="site-content">
        <div className="page-header">
            <div className="page-header-content">
                <span className="number">404</span>
                <h1>{props.title}</h1>
            </div>
        </div>
        <div className="page-content">
            <div className="container container-xs">
                <div className="text-center">
                    <p>Sidan du letar efter kan tyvärr inte hittas. Länken kan ha ändrats eller så har sidan tagits bort.</p>
                    <Link to="/" className="btn btn-link">Till startsidan</Link>
                </div>
            </div>
        </div>
    </div>
}

export default Template404;
