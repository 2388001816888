import React from 'react';
import SectionChapter from "./section.chapter";
import SectionColumns from "./section.columns";

const Section = (props) => {
	switch (props.acf_fc_layout) {
		case 'chapter':
			return <SectionChapter {...props} />
        case 'columns':
            return <SectionColumns {...props} />
	}
	return null;
}

export default Section;
