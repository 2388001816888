import React from 'react';
import SmartLink from "../../components/component.smartlink";
import TrackVisibility from 'react-on-screen';

const SectionTable = (props) => {
    return <TrackVisibility partialVisibility="partialVisibility" offset={-.25 * window.innerHeight} once="once">
        <div className="inner-section">
            <div className="container p-0">
                <div className="table-container">
                    <div className="table">
                        <div className="container">
                            <div className="table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>
                                                <strong>2019</strong>
                                            </th>
                                            <th>
                                                <strong>2018</strong>
                                            </th>
                                            <th>
                                                <strong>2017</strong>
                                            </th>
                                            <th>
                                                <strong>2016</strong>
                                            </th>
                                            <th>
                                                <strong>2015</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tr>
                                        <td>Nettoomsättning (Mkr)</td>
                                        <td>209</td>
                                        <td>178</td>
                                        <td>162</td>
                                        <td>158</td>
                                        <td>145</td>
                                    </tr>
                                    <tr>
                                        <td>Resultat efter finansiella poster (Mkr)</td>
                                        <td>24</td>
                                        <td>165</td>
                                        <td>14</td>
                                        <td>18</td>
                                        <td>-24</td>
                                    </tr>
                                    <tr>
                                        <td>Balansomslutning (Mkr)</td>
                                        <td>2 042</td>
                                        <td>1 559</td>
                                        <td>1 137</td>
                                        <td>979</td>
                                        <td>875</td>
                                    </tr>
                                    <tr>
                                        <td>Soliditet (%)</td>
                                        <td>22</td>
                                        <td>28</td>
                                        <td>23</td>
                                        <td>24</td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>Medelantalet anställda</td>
                                        <td>60</td>
                                        <td>57</td>
                                        <td>48</td>
                                        <td>40</td>
                                        <td>35</td>
                                    </tr>
                                </table>

                            </div>
            </div>
                    </div>
                    {props.pdf &&
                        <div className="table-footer">
                            <a href={props.pdf} target="_blank" className=""><i className="icon fal fa-arrow-to-bottom"></i> Hämta formella årsredovisingen</a>
                        </div>
                    }
                </div>
            </div>
        </div>
    </TrackVisibility>
}

export default SectionTable;
