import React from 'react';

const TemplatePostSingle = (props) => {
    return  <div className="site-content">
		<div className="container">

			<div className="page-header">
				<h1>{props.fields.alt_title ? props.fields.alt_title : props.title}</h1>
				{props.fields.preamble &&
				<p className="lead">{props.fields.preamble}</p>
				}
			</div>

			{props.fields.cover_image &&
			<img className="page-image" src={props.fields.cover_image.sizes.large}></img>
			}


			<div className="page-content">
				<div dangerouslySetInnerHTML={{__html: props.content}}></div>
			</div>

		</div>

		{!props.loaded && <div className="loader"></div>}

	</div>
}

export default TemplatePostSingle;
