import React from 'react';
import ReactMapboxGl, { Marker, Popup, ZoomControl } from "react-mapbox-gl";
import TrackVisibility from 'react-on-screen';
import Pagination from "../components/component.pagination";
import { h } from 'preact';

const Map = ReactMapboxGl({
    accessToken: "pk.eyJ1IjoibGlua2luYWIiLCJhIjoiY2l6OGVsbGV2MDAxZzMzbWRmZ3NtNDU5aSJ9.SJkt-82JCj_xgK2fspJ2aA",
    scrollZoom: false,
    logoPosition: 'bottom-left'
});

const InnerHTMLHelper = ({ tagName, html }) => {
    return h(tagName, {dangerouslySetInnerHTML: {__html: html}});
}

class TemplateMap extends React.Component {

    constructor()
    {
        super();
        this.state = {
            current: {
                center: [15.6, 58.420272],
                zoom: 11,
                speed: 0,
                pitch: 0,
                bearing: 0,
                popoverTitle: '',
                popoverText: ''
            },
            clicked: -1,
            markers: [],
            current_id: '',
            chapter: 0,
            chapters: {

                //Ebbepark
                'ebbepark': {
                    bearing: 10,
                    center: [15.602129, 58.404609],
                    zoom: 14.5,
                    pitch: 0,
                    speed: 0.6,
                    popoverTitle: "",
                    popoverText: ""
                },
                //Gjuteriet
                'gjuteriet': {
                    bearing: 10,
                    center: [15.625234, 58.417414],
                    zoom: 15,
                    pitch: 40,
                    speed: 0.6,
                    popoverTitle: "",
                    popoverText: ""
                },
                //vreta
                'vretakluster': {
                    bearing: 10,
                    center: [15.489946, 58.490642],
                    zoom: 12.5,
                    pitch: 40,
                    speed: 0.6,
                    popoverTitle: "",
                    popoverText: ""
                },
                //mjardevi
                'mjardevicenter': {
                    duration: 6000,
                    center: [15.559631, 58.395440],
                    bearing: 146,
                    zoom: 15.2,
                    pitch: 0,
                    popoverTitle: "",
                    popoverText: ""
                },
                //cavok
                'cavok': {
                    bearing: -35,
                    // center: [15.677082, 58.410733],
                    center: [15.674337, 58.413957],
                    zoom: 14,
                    pitch: 25,
                    speed: 0.5,
                    popoverTitle: "",
                    popoverText: ""
                },
                //natverket
                'natverketivallastaden': {
                    bearing: -35,
                    center: [15.580167, 58.393570],
                    zoom: 14,
                    pitch: 25,
                    speed: 0.5,
                    popoverTitle: "",
                    popoverText: ""
                },
                //misc
                'ovrigamiljoer': {
                    bearing: 0,
                    center: [15.6, 58.420272],
                    zoom: 11.2,
                    speed: 0.5,
                    pitch: 30,
                    popoverTitle: "",
                    popoverText: ""
                }
            },
            features: [
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Gjuteriet</h2><p>Cleantech Park: Gjuterigatan 1 A-D<br />Mutterhuset: Gjuterigatan 5<br />Gesällen, Skylten: Södra Oskarsgatan 3 A-C<br />9 139 m<sup>2</sup></p>"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.625234, 58.417414]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Meetab</h2><p>Idögatan 45<br />2 187 m<sup>2</sup></p>"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.691949099999988, 58.4245743]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Cavok District</h2><p>IMA One under uppförande<br />ca 7000 m<sup>2</sup></p>"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.674337, 58.413957]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Flyghangar</h2><p>Pilotgatan 10<br />1 856 m<sup>2</sup></p>"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.677082, 58.410733]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Vreta Kluster</h2><p>Klustervägen 11+13<br />4 239 m<sup>2</sup></p>"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.489946, 58.490642]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Mjärdevi Center</h2><p>Teknikringen 10<br />11 265 m<sup>2</sup></p>"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.559631, 58.395440]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Linköping City Airport</h2><p>Åkerbogatan 20<br />2 971 m<sup>2</sup></p>"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.657795, 58.407453]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Parkeringshus Baggen</h2><p>Kungsgatan 24<br />15 827 m<sup>2</sup><br />450 platser</p>",
                            "color" : "yellow"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.621282, 58.412839]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Parkeringshus Druvan</h2><p>Nygatan 3<br />16 990 m<sup>2</sup><br />540 platser</p>",
                            "color" : "yellow"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.630078, 58.410776]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Parkeringshus Akilles</h2><p>Storgatan-Gröngatan<br />Under ombyggnad med två plan,<br />färdigutbyggd kapacitet 620 platser.</p>",
                            "color" : "yellow"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.613385, 58.409106]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Parkeringshus Detektiven</h2><p>Drottninggatan 22<br />14 550 m<sup>2</sup><br />340 platser</p>",
                            "color" : "yellow"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.626271, 58.408615]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Ebbepark</h2><p>Westmansgatan 21-59<br />26 600 m<sup>2</sup></p>"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.602129, 58.404609]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Nätverket</h2><p>Kunkapslänken 36<br />2 100 m<sup>2</sup></p>"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.580167, 58.393570]
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                            "message": "<h2>Flustret</h2><p>Samuel Älfs Gata 4<br />313 bilplatser (60 elbilsplatser)<br />Ca 8000 m<sup>2</sup> BTA</p>",
                            "color" : "yellow"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [15.581912, 58.391225]
                        }
                    }
                ]
        }
    }

    componentDidMount()
    {
        window.addEventListener('scroll', () => {
            let sections = document.getElementsByClassName('cluster');

            let current = null;
            for (var i = 0; i < sections.length; i++) {
                let section = sections[i];
                let rect = section.getBoundingClientRect();
                if (rect.top < 500) {
                    current = section;
                }
            }

            if (current && current.id !== this.state.current_id) {
                this.setChapter({
                    target: {
                        value: current.id
                    }
                })
            }
        })
    }

    setChapter(e)
    {
        let chapter = null;

        switch (e.target.value) {
            case 'ebbepark':
                chapter = this.state.chapters.ebbepark
                break;
            case 'mjardevi-center':
                chapter = this.state.chapters.mjardevicenter
                break;
            case 'natverket':
                chapter = this.state.chapters.natverketivallastaden
                break;
            case 'cavok':
                chapter = this.state.chapters.cavok
                break;
            case 'vreta-kluster':
                chapter = this.state.chapters.vretakluster
                break;
            case 'gjuteriet':
                chapter = this.state.chapters.gjuteriet
                break;
            case 'ovrigamiljoer':
                chapter = this.state.chapters.ovrigamiljoer
                break;
        }

        let index = this.props.fields.areas.map(area => area.animations_id).indexOf(e.target.value);

        if (index >= 0) {
            chapter.popoverTitle = this.props.fields.areas[index].popup.title ? this.props.fields.areas[index].popup.title : this.props.fields.areas[index].title;
            chapter.popoverText = this.props.fields.areas[index].popup.text;
        }

        if (chapter) {
            this.setState({
                current_id: e.target.value,
                current: chapter
            })
        }

    }

    onMarkerClick(index) {
        this.setState({
            clicked: this.state.clicked == index ? -1 : index
        })
    }

    render()
    {

        return <div className="site-content">
                <div className="map-container">
            <div className="page-columns">

                    <div className="row no-gutters">
                        <div className="col-xl-6">

                                <div id="map-canvas">
                                    <Map
                                        style="mapbox://styles/linkinab/ciz8h2mo800072spm3z2ui41g"
                                        containerStyle={{
                                            height: "100vh"
                                        }} zoom={[this.state.current.zoom]} center={this.state.current.center} bearing={this.state.current.bearing} pitch={this.state.current.pitch} duration={6000} interactive={true}>
                                        {this.state.features.map((marker, i) => {
                                            return <Marker key={i}
                                                           coordinates={marker.geometry.coordinates}
                                                           anchor="bottom" className={"map-marker " + marker.properties.color}
                                                            offset={[-27, 30]} onClick={this.onMarkerClick.bind(this, i)}>
                                                <div className="hex"><span className="icon fal fa-arrow-down"></span></div>

                                            </Marker>
                                        })}

                                        {this.state.clicked >= 0 && <Popup
                                            coordinates={this.state.features[this.state.clicked].geometry.coordinates}
                                            offset={
                                                [-27, -10]
                                            }>
                                            <InnerHTMLHelper tagName="div" html={this.state.features[this.state.clicked].properties.message} />
                                        </Popup>}

                                        {(this.state.current.popoverTitle && this.state.current.popoverTitle != "Övriga företagsmiljöer") && <Popup
                                            coordinates={this.state.current.center}
                                            offset={
                                                [-27, -10]
                                            }>
                                            <h2>{this.state.current.popoverTitle}</h2>
                                            <InnerHTMLHelper tagName="p" html={this.state.current.popoverText} />
                                        </Popup>}


                                       <ZoomControl className="map-zoom-control" position="bottom-right" />
                                    </Map>
                                </div>
                            </div>
                <div className="col-xl-6">
                    <div id="clusterInfo" className="clusters-info">
                        <div className="page-columns-content">
                            <div className="page-header text-xl-left">
                                <div className="container">
                                    <span className="number">05</span>
                                    <h1>{this.props.title}</h1>
                                </div>
                            </div>
                        </div>
                        {this.props.loaded ?
                            <div className="page-content fade-in">
                                <div className="container">
                                    {this.props.fields.page_preamble &&
                                        <div className="intro text-xl-left">
                                            <p className="lead">{this.props.fields.page_preamble}</p>
                                            <hr></hr>
                                        </div>
                                    }
                                    <div dangerouslySetInnerHTML={{ __html : this.props.content}}></div>
                                </div>
                            </div>
                        :
                            <div className="loader-lines">
                                <div className="container">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        }


                        {this.props.fields.areas && this.props.fields.areas.map((area, i) => {
                         return <TrackVisibility className="cluster-container" partialVisibility offset={-.25*window.innerHeight} once>
                             <div key={i} id={area.animations_id} className="cluster">
                             <div className="inner">
                                 <div className="container">

                                       <div className="text-center">
                                         <h2>{area.title}</h2>
                                         <div className="lead">
                                             <InnerHTMLHelper tagName="div" html={area.lead} />
                                         </div>
                                         <hr></hr>
                                     </div>
                                   </div>
                                   <div className="container container-xs">
                                       <InnerHTMLHelper tagName="div" html={area.content} />
                                 </div>
                             </div>
                         </div>
                         </TrackVisibility>
                        })}
                    </div>
                </div>
                </div>
            </div>

            <div id="clusterStats" className="cluster-stats">
              <div className="container container-lg">
                <h3>Våra fastigheter</h3>

                <div className="row mt-5">
                    <div className="col-md-4">
                        <div className="statsCol">
                            <h4 className="stats-number count">53 000</h4>
                            <p>m<sup>2</sup> verksamhetsyta</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="statsCol">
                            <h4 className="stats-number md count">200</h4>
                            <p>kunder</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="statsCol">
                            <h4 className="stats-number md"><span className="count percent">7</span>%</h4>
                            <p>vakansgrad</p>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>

        {this.props.loaded &&
			<Pagination
				prevChapter={(this.props.custom.prevChapter.number == 0 ? null : this.props.custom.prevChapter)}
				nextChapter={(this.props.custom.nextChapter.title == this.props.title ? null : this.props.custom.nextChapter)}>
			</Pagination>
		}

      </div>
    }

}

export default TemplateMap;
