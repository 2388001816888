import React from 'react';

const TemplatePosts = (props) => {
    return <div className="site-content">
	    <div className="container">
        {props.posts && props.posts.map((post) => {
            return <div>
                <h1>{post.title}</h1>
                <div dangerouslySetInnerHTML={ { __html: post.content }}></div>
            </div>
        })}
        </div>
    </div>
}

export default TemplatePosts;