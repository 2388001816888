import React from 'react';

export const WPImage = (props) => {

    if (!props.url) {

        if (props.fallback) {
            return <img src={props.fallback} />
        }

        return null;
    }

    var width = "100vw";
    if (props.maxWidth) {
        width = props.maxWidth;
    }

    if (props.mime_type == 'image/svg+xml') {
        return <img src={props.url} />
    }

    var srcset = [];
    srcset.push(props.sizes.thumbnail+' '+props.sizes['thumbnail-width']+'w');
    srcset.push(props.sizes.medium+' '+props.sizes['medium-width']+'w');
    srcset.push(props.sizes.large+' '+props.sizes['large-width']+'w');
    srcset.push(props.url+' '+props.width+'w');

    var sizes = '(max-width: '+props.sizes['thumbnail-width']+'px) '+props.sizes['thumbnail-width']+'px, (max-width: '+props.sizes['medium-width']+'px) '+props.sizes['medium-width']+'px,(max-width: '+props.sizes['large-width']+'px) '+props.sizes['large-width']+'px, '+width;
    return <img srcSet={srcset.join(', ')} sizes={sizes} src={props.url} className={props.className} alt={props.alt} />

}

export class WPImageBG extends React.Component {

    constructor() {
        super();
        this.state = {
            url: ''
        }
    }

    componentDidMount() {

        this.addImage(this.props);
    }

    componentWillReceiveProps(props)
    {
        this.addImage(props);
    }

    addImage(props) {
        if (props.image) {
            var sizes = [
                {
                    url: props.image.sizes.medium,
                    width: props.image.sizes['medium-width']
                },
                {
                    url: props.image.sizes.large,
                    width: props.image.sizes['large-width']
                },
                {
                    url: props.image.url,
                    width: props.image.width
                }
            ];

            var url = props.image.url;
            var width = this.refs.imagecontainer.getBoundingClientRect().width;

            sizes.reverse().forEach((size) => {
                if (size.width >= width) {
                    url = size.url;
                }
            })

            this.setState({
                url: url
            })
        }
    }

    render() {
        if (this.state.url.length === 0) {
            return <div ref="imagecontainer" className={this.props.className}>{this.props.children}</div>;
        }

        return <div ref="imagecontainer" className={this.props.className} style={ { backgroundImage: 'url('+this.state.url+')' } }>{this.props.children}</div>;
    }

}
