import React from 'react';
import { connect } from 'react-redux';

import {Helmet} from "react-helmet";

import TemplatePage from "../templates/template.page";
import Template404 from '../templates/template.404';
import TemplatePostSingle from '../templates/template.post-single';
import TemplatePosts from '../templates/template.posts';
import Preview from "./component.preview";
import TemplateStart from "../templates/template.start";
import TemplateColumns from "../templates/template.columns";
import TemplateHighlights from "../templates/template.highlights";
import TemplateMap from "../templates/template.map";
import TemplateBoard from "../templates/template.board";
import TemplateProtected from "../templates/template.protected";

const Page = (props) => {

    if (!props.page) {
        return <div class="loader"><div className="symbol"></div></div>;
    }

    let page = props.page;
    if (props.revision != null) {
        page = { ...props.page, ...props.revisions[props.revision]};
        page.title = page.title.rendered;
        page.content = page.content.rendered;
    }

    let template = null;
    switch (props.page.template) {
        case '404':
            template = <Template404 {...page} />;
            break;
        case 'posts':
            template = <TemplatePosts {...page} />;
            break;
        case 'post-single':
            template = <TemplatePostSingle {...page} />;
            break;
	    case 'start':
		    template = <TemplateStart {...page} />;
		    break;
        case 'columns':
            template = <TemplateColumns {...page} />;
            break;
        case 'highlights':
            template = <TemplateHighlights {...page} />;
            break;
        case 'map':
            template = <TemplateMap {...page} />;
            break;
        case 'board':
            template = <TemplateBoard {...page} />;
            break;
        default:
        case 'page':
            template = <TemplatePage {...page} />;
            break;
    }

    if (props.page.protected) {
        template = <TemplateProtected {...page} />
    }

    return <div>
        {props.head &&
        <Helmet>
            <title>{props.head.title}</title>
            <meta name="description" content={props.head.description} />
        </Helmet>}
        <Preview />
        {template}
    </div>;
}

export default connect(state => state.pageState)(Page);
