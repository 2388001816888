import React from 'react';
import {connect} from 'react-redux';
import {WPImage} from "../components/component.wpimage";
import Pagination from "../components/component.pagination";

const TemplatePage = (props) => {

    let chapter = props.menu_order - 1;
    chapter = (
        chapter < 10
        ? "0" + chapter
        : chapter);

    const articles = props.fields.articles
        ? props.fields.articles
        : [];
    const articleIndex = props.articleIndex;

    let array = articles;
	let id = -1;
    let chunks = [];
    let columns = 3;
    let chunksize = Math.round(array.length / columns);

    for (var i = 0, j = 0; i < columns; i++, j += chunksize) {
        chunks[i] = array.slice(j, j + chunksize);
    }

    return <div className="site-content">

        <div className="page-header">
            <div className="page-header-content">
                <div className="container container-lg">
                    <span className="number">{chapter}</span>
                    <h1>{props.title}</h1>
                </div>
            </div>
        </div>

        <div className="page-content">
            <div className="container container-max pt-5">

                {!props.fields.articles &&
                    <div className="loader-grid">
                        <div className="row row-lg">
                            <div className="col-4">
                                <span></span>
                            </div>
                            <div className="col-4">
                                <span></span>
                            </div>
                            <div className="col-4">
                                <span></span>
                            </div>
                            <div className="col-4">
                                <span></span>
                            </div>
                            <div className="col-4">
                                <span></span>
                            </div>
                            <div className="col-4">
                                <span></span>
                            </div>
                        </div>
                    </div>
                }



                <div className="row row-lg">
                    {
                        chunks.map((items, i) => {
                            if (items.length > 0) {

                                return <div key={i} className="col-lg-4 card-column">

                                    {
                                        items.map((item, y) => {
											id++;
                                            return <button key={y} alt={id} onClick={props.openArticleModal.bind(null, id)} className="card">
                                                {item.image &&
                                                    <div className="card-image">
                                                        <img src={item.image.sizes.medium_large}></img>
                                                    </div>
                                                }
                                                <div className="card-content">
                                                    <div className="card-label">
                                                        <span className="label">{item.month}</span>
                                                    </div>
                                                    <h2 className="card-title">{item.title}</h2>
                                                    <p>{item.text}</p>
                                                    <span className="card-btn">
                                                        <i className="card-btn-icon far fa-plus"></i>
                                                        Läs mer</span>
                                                </div>
                                            </button>
                                        })
                                    }
                                </div>
                            }

							id++;
                        })
                    }
                </div>
            </div>
        </div>

        {
            articleIndex > -1 && <div id="articleModal" className={"modal" + (
                        props.loading
                        ? " loading"
                        : "")}>
                    <div className="modal-dialog">
                        <div className="container container-md">
                            <div className="modal-content">
                                <button onClick={props.openArticleModal.bind(null, -1)} className="modal-close">
                                    <i className="icon fal fa-times"></i>
                                </button>
                                <div className="row no-gutters">
                                    <div className="col-xl-6 bg-light">
                                        <div className="modal-content-image" style={{
                                                backgroundImage: "url(" + articles[articleIndex].image.sizes.large + ")"
                                            }}></div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div id="articleModalContent" className="modal-content-text">
                                            <div className="container">
                                                <span className="top-title">{articles[articleIndex].month}</span>
                                                <h2 className="modal-title">{articles[articleIndex].title}</h2>
                                                <div dangerouslySetInnerHTML={{__html : articles[articleIndex].text}} />
                                            </div>
                                        </div>
                                        <div className="modal-btn-group text-xl-right">
                                            <button onClick={props.openArticleModal.bind(null, (
                                                    articleIndex > 0
                                                    ? articleIndex - 1
                                                    : 0))} disabled={articleIndex == 0
                                                    ? "disabled"
                                                    : ""}>
                                                <i className="fal fa-chevron-left"></i>
                                                <span className="screen-reader-text">Förgående</span>
                                            </button>
                                            <button onClick={props.openArticleModal.bind(null, (
                                                    articleIndex < articles.length
                                                    ? articleIndex + 1
                                                    : 0))} disabled={articleIndex == articles.length - 1
                                                    ? "disabled"
                                                    : ""}>
                                                <i className="fal fa-chevron-right"></i>
                                                <span className="screen-reader-text">Nästa</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-bg" onClick={props.openArticleModal.bind(null, -1)}></div>
                </div>
        }

		{props.loaded &&
			<Pagination
				prevChapter={(props.custom.prevChapter.number == 0 ? null : props.custom.prevChapter)}
				nextChapter={(props.custom.nextChapter.title == props.title ? null : props.custom.nextChapter)}>
			</Pagination>
		}

        {!props.loaded && <div className="loader"></div>}

    </div>
}

export default connect(state => state.modalState, (dispatch) => {
    return {
        openArticleModal: (index) => {

            const articleModal = document.getElementById('articleModal');
            const articleModalContent = document.getElementById('articleModalContent');

            if (articleModal) {
                articleModal.scrollTop = 0
            }

            if (articleModalContent) {
                articleModalContent.scrollTop = 0
            }

            dispatch({type: 'ARTICLE_MODAL', articleIndex: index})

            setTimeout(function() {
                dispatch({type: 'ARTICLE_MODAL_LOADED'})
            }, 1000);

        }
    }
})(TemplatePage);
