import React from 'react';
import SmartLink from "../../components/component.smartlink";
import TrackVisibility from 'react-on-screen';

const SectionColumns = (props) => {
	return <TrackVisibility partialVisibility={true} offset={-.25*window.innerHeight} once={true}>
			<div className="column-group">
			<div className="row no-gutters">
				{props.chapters.map((item, i) => {

					 return <div className="column col-xl-6">
						<SmartLink url={item.url} className={"section section-" + (item.bg) + (item.image ? " section-image" : "") + ((item.bg == "image" && item.dark_mode) ? " section-dark" :"")}>
							<div className="section-content text-center">
								<div className="container container-xs">
									<span className="section-number">{(props.index + i) < 10 ? "0" + (props.index + i) : props.index + i}</span>
									<h2 className="section-title">{item.title}</h2>
									{item.preamble &&
										<p>{item.preamble}</p>
									}

									<span className="link">Till kapitel</span>
								</div>
							</div>
							{item.image &&
								<div className="section-bg" style={{backgroundImage : "url(" + item.image.sizes.large + ")"}}></div>
							}
						</SmartLink>
					</div>
				})}
			</div>
		</div>
	</TrackVisibility>
}

export default SectionColumns;
