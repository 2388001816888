import React from 'react';
import SmartLink from './component.smartlink';

const Pagination = (props) => {
    return <div className={"pagination"}>
			<div className="row no-gutters">

                {props.nextChapter &&
                    <div className="col-md-6 column order-md-2 ml-auto">
                        <SmartLink url={props.nextChapter.slug} className={props.nextChapter.thumbnail ? "has-image" : ""}>
                            <div className="column-content">
                                <span className="number">{props.nextChapter.number}</span>
                                <h3 className="title">{props.nextChapter.title}</h3>
                                <i className="icon icon-right fal fa-long-arrow-right"></i>
                            </div>
                            {props.nextChapter.thumbnail &&
                                <div className="column-bg" style={{backgroundImage :"url(" + props.nextChapter.thumbnail + ")"}}></div>
                            }
                        </SmartLink>
                    </div>
                }

				{props.prevChapter ?
					<div className="col-md column">
						<SmartLink url={props.prevChapter.slug} className={props.prevChapter.thumbnail ? "has-image" : ""}>
                            <div className="column-content">
                                <span className="number">{props.prevChapter.number}</span>
                                <h3 className="title">{props.prevChapter.title}</h3>
                                <i className="icon icon-left fal fa-long-arrow-left"></i>
                            </div>
                            {props.prevChapter.thumbnail &&
                                <div className={"column-bg" + (!props.nextChapter ? " bg-position" : "")} style={{backgroundImage :"url(" + props.prevChapter.thumbnail + ")"}}></div>
                            }
						</SmartLink>
					</div>
				:
                    <div className="col-md-6 column">
                        <SmartLink url={"/"} className="has-image">
                            <div className="column-content">
                                <span className="number"><i class="fal fa-home"></i></span>
                                <h3 className="title">Start</h3>
                                <i className="icon icon-left fal fa-long-arrow-left"></i>
                            </div>
                            <div className="column-bg" style={{backgroundImage :"url(https://2019.sanktkors.se/wp/custom/uploads/2020/03/sk-hero.jpg"}}></div>
                        </SmartLink>
                    </div>
                }


			</div>
    </div>;
}

export default Pagination;
