import React from 'react';
import {WPImage} from "../components/component.wpimage";
import { connect } from 'react-redux';
import {getProtectedPage} from "../actions";

class TemplateProtected extends React.Component {

	constructor() {
		super();
		this.state = {
			password: ""
		}
	}

	onPasswordChange (e) {
		this.setState({
			password: e.target.value
		})
	}

	onPasswordClick (id, e) {
		e.preventDefault();
		getProtectedPage({
			id: id
		},
		{
			password: this.state.password
		})
	}

	render() {
		return <div className="site-content">
			<div className="container">

				<div className="page-header">
					<h1>{this.props.title}</h1>
				</div>

				<div className="page-content text-center">
					<p>This page is password protected, enter the password below to access this page.</p>
					<form className="form-inline justify-content-center">
						<div className="form-group">
						<input type="password" className="form-control" onChange={this.onPasswordChange.bind(this)} value={this.state.password} />
						</div>
						<button onClick={this.onPasswordClick.bind(this, this.props.id)} className="btn btn-primary ml-2">Enter</button>
					</form>
				</div>

			</div>

			{!this.props.loaded && <div className="loader"></div>}

		</div>
	}
}

export default connect(state => state.pageState)(TemplateProtected);
