import React from 'react';
import SmartLink from "../../components/component.smartlink";
import { ParallaxBanner} from 'react-scroll-parallax';
import TrackVisibility from 'react-on-screen';

const SectionChapter = (props) => {

    const textAlign = (props.bg == "image" ? props.text_align : "center");

    return <TrackVisibility partialVisibility={true} offset={-.25*window.innerHeight} once={true}>
        <div className={"section section-" + props.bg}>

            {props.image &&
                <ParallaxBanner
                    className="section-bg"
                    layers={[
                        {
                            image: props.image.url,
                            amount: 0.25,
                        }
                    ]}
                >
                </ParallaxBanner>
            }

            <div className="section-content">
                <div className={"row" + (textAlign == "right" ? " justify-content-end" : "") + (textAlign == "center" ? " justify-content-center text-center" : "")}>
                    <div className="col-lg-6">
                        <SmartLink url={props.details.url} className="section-content-box">
                            <div className="section-content-box-inner">
                                <span className="section-number">{props.index < 10 ? "0" + props.index : props.index}</span>
                                <h2 className="section-title">{props.details.title}</h2>
                                <p>
                                    {props.preamble}
                                </p>
                                <hr></hr>
                                <span className="link">Till kapitel</span>
                            </div>
                        </SmartLink>
                    </div>
                </div>
            </div>
        </div>
    </TrackVisibility>
}

export default SectionChapter;
