import React from 'react';

import { withRouter } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import {CONFIG} from "../config";

const SmartLink = (props) => {

    if (props.activeClassName && props.url.indexOf(CONFIG.API.URL) >= 0) {
        return <NavLink exact activeClassName={props.activeClassName} className={props.className ? props.className : ""} to={props.url.replace(CONFIG.API.URL, '')}>{props.children}</NavLink>
    }

    if (props.url.indexOf(CONFIG.API.URL) >= 0) {
        return <Link className={props.className} to={props.url.replace(CONFIG.API.URL, '')}>{props.children}</Link>
    }

    return <a className={props.className ? props.className : ""} target={props.target} href={props.url}>{props.children}</a>
}

export default withRouter(SmartLink);
