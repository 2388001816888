import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import polyfill from 'dynamic-polyfill';

import { setPage, loadGeneral, loadMenus } from './actions';
import { store } from './store';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-62396860-3', {
    gaOptions: {
        storage: 'none'
    }
});

let ga = ReactGA.ga();
ga("set", "anonymizeIp", true);

import Main from './components/component.main';

//sass
import './../sass/main.scss';

const route = (props) => {
    window.scrollTo(0,0);
    ReactGA.pageview(window.location.pathname + window.location.search);
    setPage(store.dispatch, props.match.params, props.location.hash);
    return <Main></Main>;
}

//Hot reloading
if (module.hot) {
    module.hot.accept();
}

window.addEventListener('scroll', () => {

    if (window.pageYOffset > 100) {
      if(!store.getState().generalState.scrolled) {
        store.dispatch({
            type: 'PAGE_SCROLLED',
            scrolled: true
        })
      }
    } else {
      if (store.getState().generalState.scrolled) {
        store.dispatch({
            type: 'PAGE_SCROLLED',
            scrolled: false
        })
      }
    }

})

polyfill({
    fills: ['Promise', 'fetch', 'Map', 'Set', 'Object.assign', 'Array.prototype.find'],
    options: [],
    minify: true,
    rum: false,
    afterFill() {
        __main();
    }
});

function __main() {
    ReactDOM.render(<Provider store={store}><BrowserRouter>
        <Switch>
            <Route path="/:page/:sub/:sub2" component={route}></Route>
            <Route path="/:page/:sub" component={route}></Route>
            <Route path="/:page" component={route}></Route>
            <Route path="/" component={route} />
        </Switch>
    </BrowserRouter></Provider>, document.getElementById('root'));
}
