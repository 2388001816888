import React from 'react';
import SmartLink from './component.smartlink';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

const Header = (props) => {
	if (!props.menu.menus) {
		return null;
	}

	return <div className="site-top">
		<header className={"site-header" + (props.transparentHeader ? " transparent" : "") + (props.general.scrolled ? " scrolled" : "") + (props.menu.menu_toggled ? " open" : "")}>
			<div className="row align-items-center">
				<div className="col">
					<Link to="/" className="logo">
						<img
							src="/assets/images/logo.svg"
							className="default"
							alt={props.general.info.title}>
						</img>
						<img
							src="/assets/images/logo_inverse.svg"
							className="inverse"
							alt={props.general.info.title}>
						</img>
					</Link>
				</div>
				<div className="col col-aside text-right">
					<ul className="nav nav-right">
						<li className={"nav-item nav-item-toggle" + (props.menu.menu_toggled ? " toggled" : "")}>
							<button onClick={props.toggleMenu} className="nav-link">
								<span className="text">{props.menu.menu_toggled ? "Stäng" : "Innehåll"}</span> <span className="icon"><span></span></span>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</header>
		<div className={"site-navigation" + (props.menu.menu_toggled ? " open" : "")}>
			<div className="site-navigation-inner">
				<div className="row no-gutters">
					<div className="col-lg-6">
						<div className="site-navigation-bg" onClick={props.toggleMenu}></div>
					</div>
					<div className="col-lg-6">
						<div className="site-navigation-content">
							<div className="site-navigation-content-inner">
								<div className="container">
									{props.menu.menus.main_menu &&
										<ul>
											{props.menu.menus.main_menu.items.map((item, i) => {
												return <li key={i} style={{transitionDelay: i*.0375 + "s"}}>
													<SmartLink activeClassName="current" url={item.url}>
														<span className="symbol">{i > 0 ? (i < 10 ? "0" + i : i) : <i className="fal fa-home"></i>}</span>{item.title}
													</SmartLink>
												</li>
											})}
										</ul>
									}
								</div>
							</div>
							<div className="site-navigation-download">
								<a href={props.general.info.pdf} target="_blank"><i className="icon fal fa-arrow-to-bottom"></i> Hämta formella årsredovisningen</a>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
}

export default connect(
	state => {
		return {
			menu: state.menuState,
			general: state.generalState,
			transparentHeader: state.pageState.page && state.pageState.page.transparentHeader ? state.pageState.page.transparentHeader : ""
		}
	},
	(dispatch) => {
		return {
			toggleMenu: () => {
				dispatch({
					type: 'MENU_TOGGLED'
				})
			}
		}
	}
)(Header);
