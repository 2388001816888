import React from 'react';
import SmartLink from "../../components/component.smartlink";

const SectionDefault = (props) => {
	return <div className="inner-section" isVisible>
			<div className="container">
				<div dangerouslySetInnerHTML={{__html: props.content}} />
			</div>
		</div>
}

export default SectionDefault;
