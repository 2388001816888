import React from 'react';
import Section from "../sections/start/section.index";
import { scrollToMain } from "../actions";
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

const TemplateStart = (props) => {

	let sectionIndex = 1;

	return <div className="site-content">

			<ParallaxProvider>

				{props.fields.hero &&
					<div className="hero">
						<div className="hero-content">
								<h1>
									<span>2</span>
									<span style={{animationDelay: "1.125s"}}>0</span>
									<span style={{animationDelay: "1.175s"}}>1</span>
									<span style={{animationDelay: "1.225s"}}>9</span>
								</h1>
						</div>
						<div className="hero-content-secondary">
							<div className="row">
								<div className="col-sm-6 pl-sm-5">
									<div className="container container-xs">
										<h1>Årsredo-visning<br></br><span>2019</span></h1>
										<p>Sankt Kors Årsredovisning</p>
										<a href="" className="small">Hämta den formella årsredovisningen <i className="fal fa-angle-right"></i></a>
									</div>
								</div>
							</div>
						</div>

						<div className="hero-footer">
							<button onClick={scrollToMain}><span className="icon"></span><span className="sr-only">Gå till innehåll</span></button>
						</div>

						{props.fields.hero.bg &&
							<ParallaxBanner
				                className="hero-bg"
				                layers={[
				                    {
				                        image: props.fields.hero.bg.url,
				                        amount: 0.5,
				                    }
				                ]}
				            >
				            </ParallaxBanner>
						}

						<div className="hero-backdrop"></div>

					</div>
				}

					{props.fields.intro &&
						<div className="section section-text fade-in scroll-start">
							<div className="section-content">
								<div className="container container-sm">
									<h2 className="section-title">{props.fields.intro.title}</h2>
									<p>
										{props.fields.intro.text}
									</p>
								</div>
							</div>
						</div>
					}

					{props.fields.sections && props.fields.sections.map((section, i) => {

						let index = sectionIndex;

						if (section.acf_fc_layout == "columns") {
							sectionIndex = sectionIndex + 2;
						} else {
							sectionIndex++;
						}

						return <Section key={i} index={index} {...section} />
					})}





			</ParallaxProvider>

	</div>
}

export default TemplateStart;
