import React from 'react';
import { Link } from 'react-router-dom';
import TrackVisibility from 'react-on-screen';

const SectionNki = (props) => {    
  return <TrackVisibility partialVisibility offset={-.25*window.innerHeight} once>
          <div className="inner-section">
              <div className="container container-sm p-0">
                  <div className="chart-container">
                      <div className="container">
                          {props.title &&
                            <div className="">
                              <h3>{props.title}</h3>
                            </div>
                          }
                          <div className="chart chart-light">
                              <div className="inner">
                                <div className="bars">

                                <div className="group">
                                  <div className="bar" style={{height: "75%", animationDelay: "0s"}}><span><i>75</i></span></div>
                                  <div className="bar bar-dark" style={{height: "73%", animationDelay: ".125s"}}><span><i>73</i></span></div>
                                </div>
                                  <div className="group">
                                    <div className="bar" style={{height: "72%", animationDelay: "0s"}}><span><i>72</i></span></div>
                                    <div className="bar bar-dark" style={{height: "73%", animationDelay: ".125s"}}><span><i>73</i></span></div>
                                  </div>
                                </div>
                                <ul className="y-axis">
                                  <li><span>100</span></li>
                                  <li><span>75</span></li>
                                  <li><span>50</span></li>
                                  <li><span>25</span></li>
                                  <li><span>0</span></li>
                                </ul>
                              </div>
                              <div className="row no-gutters x-axis text-center">
                              <div className="col val">2019</div>
                                <div className="col val">2018</div>
                              </div>
                          </div>
                      </div>
                      <div className="chart-footer">
                        <span className="mr-4"><i className="fas fa-circle mr-1" style={{color: "#009d8f"}}></i> Sankt Kors</span>
                        <span><i className="fas fa-circle mr-1 text-dark"></i> Rikssnitt</span>
                      </div>
                  </div>
              </div>
          </div>
    </TrackVisibility>
}

export default SectionNki;
