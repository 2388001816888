import React from 'react';
import {WPImage} from "../components/component.wpimage";
import Pagination from "../components/component.pagination";

const TemplateBoard = (props) => {


	return <div className="site-content">
			{props.fields.board_image &&
					<div className="page-image-container">
						<div className="page-header">
							<div className="container">
								<span className="number">12</span>
								<h1>{props.title}</h1>
							</div>
						</div>
						<img className="page-image" src={props.fields.board_image.sizes.large}></img>
						<div className="page-image-caption">
							<div className="container">
								<div className="page-image-caption-inner" dangerouslySetInnerHTML={{__html: props.fields.board_image_text}}></div>
							</div>
						</div>
					</div>
			}


		{!props.loaded && <div className="loader"></div>}


		{props.loaded &&
			<Pagination
				prevChapter={(props.custom.prevChapter.number == 0 ? null : props.custom.prevChapter)}
				nextChapter={(props.custom.nextChapter.title == props.title ? null : props.custom.nextChapter)}>
			</Pagination>
		}

	</div>
}

export default TemplateBoard;
