import WPJSApi from 'wordpress-js-api-wrapper';

import { CONFIG } from './config';
import {store} from "./store";

const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

import animateScrollTo from 'animated-scroll-to';

let wpOptions = {
    onSuccess: (rsp) => {
        return entities.decode(rsp);
    }
};

if (CONFIG.API.REQUIRE_AUTH) {
	wpOptions = {
		beforeSend: (req) => {
			req.withCredentials = "true";
			req.setRequestHeader("Authorization", "Basic " + btoa(CONFIG.API.USERNAME+":"+CONFIG.API.PASSWORD));
			return req;
		},
		onSuccess: (rsp) => {
			return entities.decode(rsp);
		}
	}
}

let WPJSApiSettings = WPJSApi.create('custom', 'settings');
let WPJSApiPreview = WPJSApi.create('custom', 'preview');

WPJSApi.init(CONFIG.API.URL, wpOptions);

export const getProtectedPage = (params, query) => {

	let page = store.getState().pageState.page;

	let dispatch = store.dispatch;


	if (query.password) {

		dispatch({
			type: 'PAGE_LOADING'
		})


		WPJSApi.Pages.get(params.id, { password: query.password }).then((rsp) => {

			page.loaded = true;

			var rspPage = rsp;

			dispatch({
				type: 'PAGE_PASSWORD_SET',
				id: params.id,
				slug: rspPage.slug,
				password: query.password
			})


			page.id = rspPage.id;
			page.title = rspPage.title.rendered;
			page.template = rspPage.template.replace("template-", "").replace(".php", "");
			page.content = rspPage.content.rendered;
			page.meta_seo = rspPage.meta_seo;

			page.fields = rspPage.fields ? rspPage.fields : {};
			page.custom = rspPage.custom ? rspPage.custom : {};
			page.protected = false;

			if (page.type !== 'page') {
				page.template = page.type+'-single';
			}

			if (rspPage.is_front_page) {
				page.template = 'start';
			}


			dispatch({
				type: 'PAGE_LOADED',
				page: page
			})

		}).catch((e) => {
			dispatch({
				type: 'PAGE_LOADED',
				page: page
			})
		})
	}



}

export const setPage = (dispatch, params, hash, query) => {

	let callback = () => {

		let page = {
			title: '',
			slug: '',
			template: '',
			content: '',
			type: 'page',
			fields: {},
			custom: {}
		};

		var search = CONFIG.defaultPageSlug;

		if (params.page) {
			search = params.page;
		}
		if (params.sub) {
			search = params.sub;
		}
		if (params.sub2) {
			search = params.sub2;
		}

		let cachedPages = store.getState().pagePasswordState.pages;
		if (cachedPages) {
			let cacheIndex = cachedPages.map(page => page.slug).indexOf(search);
			if (cacheIndex >= 0) {
				params.id = cachedPages[cacheIndex].id;
				query = { password:  cachedPages[cacheIndex].password }
			}
		}

		let sitemap = [];

		if (store.getState().generalState.sitemap) {
			sitemap = store.getState().generalState.sitemap;
		}

		let index = sitemap.map((page) => {
			return page.slug;
		}).indexOf(search);


		if (index >= 0 && !params.id) {
			let template = sitemap[index].template.replace("template-", "").replace(".php", "");
			let isTest = store.getState().pageState.page && store.getState().pageState.page.template == template;
			dispatch({
				type: 'PAGE_PRE_LOADED',
				page: {
					loaded: false,
					menu_order: sitemap[index].menu_order,
					title: sitemap[index].title,
					template: sitemap[index].template.replace("template-", "").replace(".php", ""),
					fields: isTest ? {} : sitemap[index].fields,
					transparentHeader : sitemap[index].transparentHeader,
					custom: {}
				},
				head: {
					title: getPageTitle(sitemap[index].title),
					description: getPageDescription(sitemap[index].description)
				}
			})
			if (isTest) {
				setTimeout(() => {
					dispatch({
						type: 'PAGE_PRE_LOADED',
						page: {
							loaded: false,
							menu_order: sitemap[index].menu_order,
							title: sitemap[index].title,
							template: sitemap[index].template.replace("template-", "").replace(".php", ""),
							fields: sitemap[index].fields,
							transparentHeader : sitemap[index].transparentHeader,
							custom: {}
						},
						head: {
							title: getPageTitle(sitemap[index].title),
							description: getPageDescription(sitemap[index].description)
						}
					})
				}, 1)
			}
		} else if (!params.id) {
			dispatch({
				type: 'PAGE_LOADING'
			})
		}

		dispatch({
			type: 'MENU_CURRENT',
		})

		let endpoint = WPJSApi.Pages;
		let req = {
			slug: search
		}

		if (params.sub) {
			CONFIG.customPostTypes.forEach((cpt) => {
				if (params.page == cpt.rewrite) {
					endpoint = WPJSApi.create('wp', cpt.endpoint ? cpt.endpoint : cpt.type);
					page.type = cpt.type;
				}
			})
		}

		let request = null;

		if (params.id) {
			request = WPJSApi.Pages.get(params.id, query);
		} else {
			request = endpoint.list(req);
		}

		request.then((rsp) => {

			page.loaded = true;



			if (rsp.length == 0) {
				page.title = 'Sidan kan inte hittas';
				page.slug = '404';
				page.template = '404';

                dispatch({
                    type: 'PAGE_LOADED',
                    page: page
                })
			} else {

				var rspPage = rsp[0];

				if (params.id) {
					rspPage = rsp;
				}

				page.id = rspPage.id;
				page.title = rspPage.title.rendered;
				page.template = rspPage.template.replace("template-", "").replace(".php", "");
				page.content = rspPage.content.rendered;
				page.meta_seo = rspPage.meta_seo;
				page.menu_order = rspPage.menu_order;

				page.fields = rspPage.fields ? rspPage.fields : {};
				page.custom = rspPage.custom ? rspPage.custom : {};

				if (page.type !== 'page') {
					page.template = page.type+'-single';
				}

				if (rspPage.is_front_page) {
					page.template = 'start';
				}

				if ((page.fields.page_image && page.template == '') || page.template == 'start') {
					page.transparentHeader = true;
				}


				if (rspPage.content.protected && !params.id) {
					page.protected = true;
					page.content = null;
					page.fields = {};
					page.custom = {};
				}

                dispatch({
                    type: 'PAGE_LOADED',
                    page: page,
                    head: {
                        title: getPageTitle(page.meta_seo.title),
                        description: getPageDescription(page.meta_seo.description)
                    }
                })

			}


			// setTimeout(() => {
			// 	let wrapper = document.getElementById('wrapper');
			// 	if (wrapper) {
			// 		wrapper.style.transform = "translateY(0px)";
			// 	}
			//
			// 	let siteContent = document.getElementById('wrapperInner');
			// 	let siteContentHeight = siteContent.offsetHeight;
			// 	if (siteContentHeight) {
			// 		document.getElementById('body').style.height = siteContentHeight + "px";
			// 	}
			// }, 20)




			//Enable revisions
			let url = new URL(window.location.href);
			if (url.searchParams.get('preview_id')) {
				if (typeof _nonce !== 'undefined') {
					let preview_id = url.searchParams.get('preview_id');
					let WPJSApiRevisions = WPJSApi.create('wp', 'pages/'+preview_id+'/revisions', 'v2');
					WPJSApiRevisions.list({'_wpnonce' : _nonce}).then((rsp) => {
						dispatch({
							type: 'PAGE_REVISIONS_LOADED',
							revisions: rsp,
							revision: 0
						})
					})
				} else {
					console.error('Trying to get page revisions but no auth token, must run inside wp environment.')
				}
			}

			if (hash) {
				setTimeout(() => {
					var element = document.getElementById(hash.replace('#', ''));
					if (element) {
						window.scrollTo(0, element.getBoundingClientRect().top);
					}
				}, 50)

			}

		}).catch(() => {

		})

	}

	if (store.getState().generalState.info) {
		callback();
	} else {
		loadConfig(store.dispatch, callback);
	}
}


export const getPageTitle = (title) => {
    if (title == "Start") {
        return CONFIG.titleFormat.replace('{title}', store.getState().generalState.info.title + " | " + store.getState().generalState.info.description);
    } else {
        return CONFIG.titleFormat.replace('{title}', title + " | " + store.getState().generalState.info.title);
    }
}
export const getPageDescription = (desc) => {
	return desc ? desc : store.getState().generalState.info.description;
}

export const loadConfig = (dispatch, cb) => {

	WPJSApiSettings.list({ enableSitemap: CONFIG.enableSitemap ? 1 : 0 }).then((rsp) => {
		dispatch({
			type: 'GENERAL_LOADED',
			info: rsp.info,
			sitemap: rsp.sitemap ? rsp.sitemap : []
		});

		CONFIG.defaultPageSlug = rsp.config.defaultPageSlug;
		CONFIG.customPostTypes = rsp.config.cpt;

		dispatch({
			type: 'MENUS_LOADED',
			menus: rsp.menus
		});

		if (cb) {
			cb();
		}
	})

}

export const scrollToMain = () => {
    let main = document.getElementsByClassName('scroll-start');
    if (main.length > 0) {

        animateScrollTo(main[0], {
            speed: 800,
			verticalOffset: 5
        });
    }
}
