import React from 'react';
import SmartLink from "../../components/component.smartlink";

const SectionVideo = (props) => {

	return <div className="inner-section" isVisible>
			<div className="container container-sm">
					<div className="row justify-content-center">
						{props.items.map((item,i) => {
							return <div className={"text-center col-lg" + (i > 0 ? "-6 mb-1" : "-12 mb-3")}>
								<div className="video-container">
									<div dangerouslySetInnerHTML={{__html : item.video}} />
								</div>
								<h3 className="mt-2">{item.title}</h3>
							</div>
						})}
					</div>

			</div>
		</div>
}

export default SectionVideo;
