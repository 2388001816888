import React from 'react';
import SmartLink from "../../components/component.smartlink";
import TrackVisibility from 'react-on-screen';

const SectionImage = (props) => {
	return <TrackVisibility className={"page-section" + (props.image.caption ? " page-section-slim" : "")} partialVisibility offset={-.25*window.innerHeight} once>
		<div className="inner-section">
			<div className="container container-sm">
				<img src={props.image.sizes.large}></img>
			</div>
			{props.image.caption &&
				<div className="wp-caption">
					<div className="container">
						<div className="wp-caption-text">{props.image.caption}</div>
					</div>
				</div>
			}
		</div>
	</TrackVisibility>
}

export default SectionImage;
