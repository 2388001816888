import React from 'react';
import { connect } from 'react-redux';

const Preview = (props) => {

	if (!props.revisions) {
		return null;
	}

	return  <div id="preview-bar">
		<button className={"btn " + (props.revision == null ? "btn-outline-primary" : "btn-outline-secondary")} onClick={props.setRevision.bind(null, null)}>Current</button>
		{props.revisions.map((rev, i) => {
			return <button className={"btn " + (props.revision == i ? "btn-outline-primary" : "btn-outline-secondary")} onClick={props.setRevision.bind(null, i)}>{ rev.slug.indexOf('revision') >= 0 ? "Old ("+rev.date+")" : "Preview"}</button>
		})}
	</div>
}

export default connect(state => state.pageState, (dispatch) => {
	return {
		setRevision: (rev) => {
			dispatch({
				type: 'PAGE_REVISION_SET',
				revision:   rev
			})
		}
	}
})(Preview);
