import React from 'react';
import SmartLink from "../../components/component.smartlink";
import TrackVisibility from 'react-on-screen';

const SectionQuote = (props) => {
	return <TrackVisibility partialVisibility offset={-.25*window.innerHeight} once>
		<div className="inner-section">
			<div className="container">

				<div className="quote">
					<div className="row">
						<p className="lead">{props.text}</p>
						{props.name &&
							<p className="name">{props.name}</p>
						}
					</div>
				</div>
			</div>
		</div>
	</TrackVisibility>
}

export default SectionQuote;
