import React from 'react';
import SmartLink from "../../components/component.smartlink";
import TrackVisibility from 'react-on-screen';

const SectionStats = (props) => {

	function numberWithThousands(x) {
	  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	}

	return <TrackVisibility className="page-section" partialVisibility offset={-.25*window.innerHeight} once>
		<div className="inner-section">
			<div className="container container-sm">

				<div className="well">

				<h2>{props.title}</h2>

					<div className="row justify-content-center">
						{props.items.map((item,i) => {

							const multi = (props.items.length > 1);

							return <div className={"mb-5 col-lg" + (props.items.length > 3 ? "-6" : "")}>
								<h3 className={(props.items.length == 3) ? "sm" : ""}>
								{numberWithThousands(item.value)}{item.percentage ? "%" : ""}</h3>
								{item.text &&
									<p>{item.text}</p>
								}
							</div>
						})}
					</div>
				</div>
			</div>
		</div>
	</TrackVisibility>
}

export default SectionStats;
