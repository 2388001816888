const API_LIVE = {
    URL: window.location.protocol + '//' + window.location.hostname,
    REQUIRE_AUTH: false
}

const API_LOCAL = {
    URL: 'http://sanktkors2019.acc.linkin.se',
    REQUIRE_AUTH: true,
    USERNAME: 'sk',
    PASSWORD: 'sk'
}

export const CONFIG = {
    API: process.env.NODE_ENV === 'development' ? API_LOCAL : API_LIVE,
	customPostTypes: null,
    titleFormat: '{title}',
    enableSitemap: true
}
