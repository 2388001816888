import React from 'react';
import {WPImage} from "../components/component.wpimage";
import SmartLink from "../components/component.smartlink";
import Section from '../sections/page/section.index';
import { scrollToMain } from "../actions";
import Pagination from "../components/component.pagination";

const TemplatePage = (props) => {

	let chapter = props.menu_order - 1;
		chapter = (chapter < 10 ? "0" + chapter : chapter);

	return <div className="site-content">

			<div className={"page-header" + (props.transparentHeader ? " page-header-bg" : "")}>

					<div className="page-header-content">
						<div className="container container-sm">
							<span className="number">{chapter}</span>
							<h1>{props.title}</h1>
						</div>
					</div>

					<div className="page-header-footer">
						<button onClick={scrollToMain} className="page-header-btn">
							<span className="icon"></span><span className="sr-only">Gå till innehåll</span>
						</button>
					</div>



				{(props.fields.page_image) &&
					<div className="page-header-image-container">
						<div className="page-header-image" style={{backgroundImage : "url(" + props.fields.page_image.sizes.large + ")"}}></div>
						<div className="page-header-backdrop"></div>
					</div>
				}
			</div>

			{props.content &&
				<div className="page-content scroll-start">

					{props.fields.page_preamble &&
						<div className="intro">
							<div className="container container-sm">
								<p className="lead">{props.fields.page_preamble}</p>
							</div>
						</div>
					}

					<div className="container">
						{props.fields.page_preamble &&
							<hr></hr>
						}
						<div dangerouslySetInnerHTML={{__html: props.content}}></div>


					</div>

						{props.fields.sections && props.fields.sections.map((section, i) => {
							return <Section key={i} {...section} />
						})}

				</div>
			}




			{props.loaded &&
				<Pagination
					prevChapter={(props.custom.prevChapter.number == 0 ? null : props.custom.prevChapter)}
					nextChapter={(props.custom.nextChapter.title == props.title ? null : props.custom.nextChapter)}>
				</Pagination>
			}



	</div>
}

export default TemplatePage;
