import React from 'react';
import SmartLink from "../../components/component.smartlink";
import TrackVisibility from 'react-on-screen';

const SectionColumns = (props) => {

	const smallImage = (props.image_size == "small");

	return <TrackVisibility partialVisibility offset={-.25*window.innerHeight} once>
		<div className="inner-section">
			<div className={"container" + (smallImage ? " container-sm" : "")}>

				<div className={"row row-md" + (props.text.length < 300 ? " align-items-center" : "")}>
					<div className={"mb-3 mb-lg-0" + (smallImage ? " col-4" : " col-lg-6") + (props.image_position == 'right' ? " order-lg-2" : "")}>
						<div className={"image" + (!smallImage ? " image-rounded" : "")} style={{backgroundImage : "url(" + props.image.sizes.medium + ")"}}></div>
					</div>
					<div className="col-md">
						<div className={!smallImage ? "text-center text-lg-left" : ""}>
							{props.top_title &&
								<p className="top-title mb-2" style={{color: props.top_title_color}}>{props.top_title}</p>
							}
							<h3 className={smallImage ? "mt-0" : ""}>{props.title}</h3>
							<p>{props.text}</p>
						</div>
					</div>

				</div>

			</div>
		</div>
	</TrackVisibility>
}

export default SectionColumns;
